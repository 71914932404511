<template>
    <div>
          <!-- Personality Matching -->
                    <div
                        class="tw-flex tw-flex-col tw-gap-3 md:tw-gap-7 tw-shadow-sm tw-w-full tw-h-full tw-bg-white tw-px-4 md:tw-px-8 tw-py-7 tw-mt-4 tw-rounded-xl">
                        <h1
                            class="tw-text-[#1f1f1f] tw-text-xl tw-leading-loose md:tw-text-2xl tw-font-semibold  md:tw-leading-10">
                            Personality Matching Analysis
                        </h1>
                        <div
                            class="tw-flex-col tw-justify-center tw-items-start tw-gap-3.5 md:tw-items-center tw-inline-flex tw-pb-4 md:tw-flex-row md:tw-justify-start md:tw-gap-7">
                            <div
                                class="tw-w-full md:tw-w-max tw-flex tw-flex-col tw-py-4 md:tw-border-e md:tw-border-b-0 tw-border-b-2">
                                <div
                                    class="md:tw-w-24 tw-w-40 tw-text-black tw-text-2xl tw-font-semibold tw-leading-10">
                                    89%
                                </div>

                                <div
                                    class="tw-w-72 md:tw-w-48 tw-text-[#f28100] tw-text-lg tw-font-semibold  tw-leading-7">
                                    Extraversion
                                </div>
                            </div>
                            <div
                                class="tw-w-full md:tw-w-max tw-flex tw-flex-col tw-py-4 md:tw-border-e md:tw-border-b-0 tw-border-b-2">
                                <div
                                    class="md:tw-w-24 tw-w-40 tw-text-black tw-text-2xl tw-font-semibold tw-leading-10">
                                    49%
                                </div>

                                <div
                                    class="tw-w-72 md:tw-w-48 tw-text-[#ff6f59] tw-text-lg tw-font-semibold  tw-leading-7">
                                    Neuroticism
                                </div>
                            </div>
                            <div
                                class="tw-w-full md:tw-w-max tw-flex tw-flex-col tw-py-4 md:tw-border-e md:tw-border-b-0 tw-border-b-2">
                                <div
                                    class="md:tw-w-24 tw-w-40 tw-text-black tw-text-2xl tw-font-semibold tw-leading-10">
                                    83
                                </div>

                                <div
                                    class="tw-w-72 md:tw-w-48 tw-text-[#3bd4ae] tw-text-lg tw-font-semibold  tw-leading-7">
                                    Agreeableness
                                </div>
                            </div>
                            <div
                                class="tw-w-full md:tw-w-max tw-flex tw-flex-col tw-py-4 md:tw-border-e md:tw-border-b-0 tw-border-b-2">
                                <div
                                    class="md:tw-w-24 tw-w-40 tw-text-black tw-text-2xl tw-font-semibold tw-leading-10">
                                    83%
                                </div>

                                <div
                                    class="tw-w-72 md:tw-w-48 tw-text-[#ef6e4d] tw-text-lg tw-font-semibold  tw-leading-7">
                                    Conscientiousness
                                </div>
                            </div>
                            <div class="tw-w-full md:tw-w-max tw-flex tw-flex-col tw-py-4">
                                <div
                                    class="md:tw-w-24 tw-w-40 tw-text-black tw-text-2xl tw-font-semibold tw-leading-10">
                                    83%
                                </div>

                                <div
                                    class="tw-w-72 md:tw-w-48 tw-text-[#3bd4ae] tw-text-lg tw-font-semibold  tw-leading-7">
                                    Openness
                                </div>
                            </div>
                        </div>
                    </div>



                    <!-- Agreeableness -->
                    <div
                        class="tw-flex-col tw-justify-start tw-items-start tw-gap-6 tw-inline-flex md:tw-gap-7 tw-shadow-sm tw-w-full tw-h-full tw-bg-white md:tw-px-8 md:tw-py-7 tw-mt-4 tw-rounded-xl tw-px-5 tw-py-7">
                        <h2
                            class="tw-text-[#1f1f1f] tw-text-xl tw-leading-loose md:tw-text-2xl tw-font-semibold  md:tw-leading-10 tw-py-1 tw-w-max">
                            Agreeableness
                        </h2>

                        <div
                            class="tw-self-stretch tw-grow tw-shrink tw-basis-0 tw-flex-col tw-justify-start tw-items-start tw-gap-1 tw-flex">
                            <h2
                                class="tw-self-stretch tw-text-base tw-text-[#1f1f1f] md:tw-text-lg tw-font-semibold  tw-leading-7">
                                High Score Indicates
                            </h2>
                            <div
                                class="tw-self-stretch tw-gap-2.5 md:tw-items-center tw-inline-flex md:tw-flex-row tw-flex-col tw-items-start">
                                <div
                                    class=" tw-h-7 tw-px-4 tw-py-0.5 tw-bg-[#f6f6f6] tw-rounded-2xl tw-justify-center tw-items-center tw-gap-2.5 tw-flex">
                                    <p class="tw-text-[#77838f] tw-text-sm tw-font-normal  tw-leading-normal">
                                        May Think Negativly
                                    </p>
                                </div>
                                <div
                                    class=" tw-h-7 tw-px-4 tw-py-0.5 tw-bg-[#f6f6f6] tw-rounded-2xl tw-justify-center tw-items-center tw-gap-2.5 tw-flex">
                                    <div class="tw-text-[#77838f] tw-text-sm tw-font-normal  tw-leading-normal">
                                        More Express Negative Emotions.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tw-h-24 tw-flex-col tw-justify-start tw-items-start tw-gap-1 tw-flex">
                            <h2
                                class="md:tw-text-lg tw-self-stretch tw-text-[#1f1f1f] tw-text-base tw-font-semibold  tw-leading-7">
                                Your Inspected Workplace Behaviour
                            </h2>
                            <div
                                class="tw-self-stretch tw-gap-2.5 md:tw-items-center tw-inline-flex md:tw-flex-row tw-flex-col tw-items-start">
                                <div
                                    class=" tw-h-7 tw-px-4 tw-py-0.5 tw-bg-[#f6f6f6] tw-rounded-2xl tw-justify-center tw-items-center tw-gap-2.5 tw-flex">
                                    <p class="tw-text-[#77838f] tw-text-sm tw-font-normal  tw-leading-normal">
                                        May Think Negativly
                                    </p>
                                </div>
                                <div
                                    class=" tw-h-7 tw-px-4 tw-py-0.5 tw-bg-[#f6f6f6] tw-rounded-2xl tw-justify-center tw-items-center tw-gap-2.5 tw-flex">
                                    <p class="tw-text-[#77838f] tw-text-sm tw-font-normal  tw-leading-normal">
                                        More Express Negative Emotions.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>