<template>
    <div class="video-interview">
        <div class="container tw-px-0 md:tw-px-4">
            <section class="tw-px-0 video-interview__wrapper">
                <h2 class="tw-text-black tw-text-xl tw-font-semibold font-['Poppins'] tw-leading-9 tw-mt-8 tw-mb-4 tw-px-4">Assessment Report</h2>
                <!-- ProfileCard -->
                <div
                    class="tw-flex tw-items-center tw-justify-between tw-p-4 tw-bg-white tw-rounded-xl xl:tw-flex-row tw-gap-2.5 tw-flex-col tw-mx-4">
                    <div class="tw-flex tw-items-center tw-flex-col xl:tw-flex-row xl:tw-gap-6">
                        <img src="@/assets/images/video-interviews/images/Rectangle.png" alt="Profile Picture"
                            class="tw-w-24 tw-h-24 tw-rounded-full" />
                        <div class="tw-flex tw-flex-col tw-items-center xl:tw-items-start">
                            <h2
                                class="tw-text-black tw-text-3xl tw-font-semibold tw-leading-10 sm:tw-text-2xl sm:tw-leading-9">
                                Ahmed 37
                            </h2>
                            <p
                                class="tw-text-[#422e87] tw-text-xl tw-font-medium  tw-leading-9 sm:tw-text-base sm:tw-leading-relaxed">
                                Frontend Developer
                            </p>
                        </div>
                    </div>
                    <button
                        class=" tw-w-full md:tw-w-auto tw-bg-[#FFC629] tw-px-4 tw-py-2 tw-rounded-md  tw-text-black tw-text-xl tw-font-semibold tw-leading-normal">
                        View Profile
                    </button>
                </div>

                <!-- navbar -->
                <div
                    class="tw-w-auto tw-mt-4 tw-bg-transparent md:tw-bg-white xl:tw-rounded-full tw-px-0 md:tw-px-4 tw-py-2 tw-overflow-hidden tw-mb-0 md:tw-mb-0 md:tw-mx-4">
                    <div
                        class="tw-snap-x tw-relative tw-w-full tw-flex tw-gap-6 tw-overflow-x-auto md:tw-overflow-x-hidden tw-justify-start tw-items-center tw-pb-2 md:tw-pb-0">
                        <div @click="activeTab = 1"
                            :class="activeTab == 1 ? 'tw-bg-[#FFC629] tw-px-6 tw-py-3 md:tw-px-8 md:tw-py-2 tw-rounded-full tw-text-[#1e2022] tw-w-full md:tw-w-auto' : 'tw-text-[#484d54] tw-w-full md:tw-w-auto'"
                            class="tw-snap-center tw-text-sm tw-font-semibold tw-cursor-pointer tw-leading-none tw-text-center xl:tw-text-xl xl:tw-leading-relaxed tw-h-auto tw-flex tw-justify-center tw-ms-4 md:tw-ms-0 tw-ps-4">
                            Overall Score
                        </div>
                        <div @click="activeTab = 2"
                            :class="activeTab == 2 ? 'tw-bg-[#FFC629] tw-px-6 tw-py-3  md:tw-px-8 md:tw-py-2 tw-rounded-full tw-text-[#1e2022] tw-w-full md:tw-w-auto' : 'tw-text-[#484d54] tw-w-full md:tw-w-auto'"
                            class="tw-snap-center tw-text-sm tw-font-semibold tw-cursor-pointer tw-leading-none tw-text-center xl:tw-text-xl xl:tw-leading-relaxed tw-h-auto tw-flex tw-justify-center">
                            Interview Questions
                        </div>
                        <div @click="activeTab = 3"
                            :class="activeTab == 3 ? 'tw-bg-[#FFC629] tw-px-6 tw-py-3  md:tw-px-8 md:tw-py-2 tw-rounded-full tw-text-[#1e2022] tw-w-full md:tw-w-auto' : 'tw-text-[#484d54] tw-w-full md:tw-w-auto'"
                            class="tw-snap-center tw-text-sm tw-font-semibold tw-cursor-pointer tw-leading-none tw-text-center xl:tw-text-xl xl:tw-leading-relaxed tw-h-auto tw-flex tw-justify-center md:tw-pe-0 tw-pe-4">
                            Personality Analysis
                        </div>
                    </div>
                </div>


                <!-- pieChart copy -->
                <div class="tw-px-4">
                    <div v-if="activeTab == 1">
                        <OverallScore />
                    </div>

                    <div v-if="activeTab == 2">
                        <InterviewQuestions />
                    </div>

                    <div v-if="activeTab == 3">
                        <PersonalityAnalysis />
                    </div>
                </div>

          

            </section>

        </div>
    </div>
</template>

<script>
import OverallScore from '@/components/videoInterviews/overallScore.vue';
import InterviewQuestions from '@/components/videoInterviews/interviewQuestions.vue';
import PersonalityAnalysis from '@/components/videoInterviews/personalityAnalysis.vue';

export default {
    data() {
        return {
            activeTab: 1,
            talentVideoInterview: []
        }
    },
    components: {
        OverallScore,
        InterviewQuestions,
        PersonalityAnalysis
    },
    created() {
        this.getTalentVideoInterview();
    },
    methods: {
        async getTalentVideoInterview() {
            await this.axios
                .get(`/api/talent-video-interviews/${this.$route.params.id}`)
                .then((res) => {
                    res
                    // res.data.data ||
                    this.talentVideoInterview = [
                        {
                            "result": [
                                {
                                    "overall_score": 26,
                                    "pass": "fail",
                                    "competencies": [
                                        {
                                            "name": "character building",
                                            "score": 40
                                        }
                                    ],
                                    "applicant_traits": [
                                        {
                                            "name": "agreeableness",
                                            "score": 71
                                        }
                                    ],
                                    "emotions": [
                                        {
                                            "name": "happiness",
                                            "score": 80
                                        }
                                    ],
                                    "answers": [
                                        {
                                            "question": "Introduce yourself?",
                                            "answer": "https://storage.velents.com/videos/users/applying/119098/673f37e849984_640.mp4",
                                            "score": 0.4,
                                            "score_per_attribute": [
                                                {
                                                    "name": "Clear and concise communication skills",
                                                    "score": 0
                                                }
                                            ],
                                            "transcribing": "Hello, I'm just testing the video interview introduced from Vlents...",
                                            "feedback_en": "The candidate's answer is incomplete and lacks clarity.",
                                            "feedback_ar": "تفتقر استجابة المرشح إلى التفاصيل."
                                        }
                                    ],
                                    "fluency_level": "string",
                                    "overall_feedback_en": "string",
                                    "overall_feedback_ar": "string",
                                    "five_traits_scores": {
                                        "extraversion": 0.8,
                                        "neuroticism": 0.3,
                                        "agreeableness": 0.72,
                                        "conscientiousness": 0.72,
                                        "openness": 0.67
                                    }
                                }
                            ],
                            "video_interview": [
                                {
                                    "id": 1,
                                    "title": "Video Interview",
                                    "test_provider": {
                                        "id": 1,
                                        "name": "value"
                                    }
                                }
                            ]
                        }
                    ];
                });
        }
    },
}
</script>


<style lang="scss">
.video-interview {
    &__wrapper {
        position: relative;
        z-index: 0;
        border-radius: 18px;
        margin: 10px auto;

       
    }

    .tw-snap-x {
        overflow-x: auto;
        white-space: nowrap;
        margin: auto;
        scroll-snap-type: x mandatory;


    }


    .tw-snap-center {
        scroll-snap-align: center;
        scroll-margin-right: 1rem;
        scroll-margin-left: 1rem;
    }
}
</style>
