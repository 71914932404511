<template>
  <div>
    <div class="tw-flex md:tw-flex-row tw-flex-col tw-gap-4">
      <div
        class="tw-px-8 tw-py-7 tw-bg-white tw-rounded-lg tw-mt-4 tw-flex tw-flex-col tw-items-start tw-gap-7 tw-shadow-sm tw-w-full">
        <div class="tw-text-[#1f1f1f] tw-text-2xl tw-font-semibold font-['Poppins'] tw-leading-10 tw-mb-4">
          Answer criteria score
        </div>

        <div class="tw-flex md:tw-flex-row tw-flex-col tw-gap-4">
          <div class="tw-w-full tw-flex tw-flex-col tw-items-center">
            <div class="tw-flex tw-items-center tw-justify-center tw-w-40 tw-h-40">
              <svg class="tw-w-full tw-h-full" viewBox="0 0 36 36">
                <!-- Background Circle -->
                <circle class="tw-text-purple-200" cx="18" cy="18" r="12" fill="transparent" stroke="currentColor"
                  stroke-width="8"></circle>
                <!-- Progress Circle -->
                <circle class="tw-text-[#422E87]" cx="18" cy="18" r="12" fill="transparent" stroke="currentColor"
                  stroke-width="8" stroke-dasharray="60, 100" transform="rotate(-90 18 18)"></circle>
                <!-- Center Text -->
                <text x="18" y="19" class="tw-text-black tw-text-[4px] tw-font-semibold tw-text-center"
                  text-anchor="middle" alignment-baseline="middle">
                  60%
                </text>
              </svg>
            </div>

            <div class="tw-text-center tw-text-[#1f1f1f] tw-text-xl tw-font-semibold tw-mt-4 tw-leading-10">Question1
            </div>

            <div class="tw-text-center tw-text-black/50 tw-text-sm tw-font-normal tw-mt-4 tw-leading-snug">Describe a
              major change that occurred in a job that you held. How did you adapt to this change?</div>

          </div>
          <div class="tw-w-full tw-flex tw-flex-col tw-items-center">
            <div class="tw-flex tw-items-center tw-justify-center tw-w-40 tw-h-40">
              <svg class="tw-w-full tw-h-full" viewBox="0 0 36 36">
                <!-- Background Circle -->
                <circle class="tw-text-[#FFC62933]" cx="18" cy="18" r="12" fill="transparent" stroke="currentColor"
                  stroke-width="8"></circle>
                <!-- Progress Circle -->
                <circle class="tw-text-[#FFC629]" cx="18" cy="18" r="12" fill="transparent" stroke="currentColor"
                  stroke-width="8" stroke-dasharray="50, 100" transform="rotate(-90 18 18)"></circle>
                <!-- Center Text -->
                <text x="18" y="19" class="tw-text-black tw-text-[4px] tw-font-semibold tw-text-center"
                  text-anchor="middle" alignment-baseline="middle">
                  50%
                </text>
              </svg>
            </div>

            <div class="tw-text-center tw-text-[#1f1f1f] tw-text-xl tw-font-semibold tw-leading-10 tw-mt-4 ">Question2
            </div>

            <div class="tw-text-center tw-text-black/50 tw-text-sm tw-font-normal tw-leading-snug tw-mt-4">Describe a
              major change that occurred in a job that you held. How did you adapt to this change?</div>

          </div>
        </div>
      </div>
      <div
        class="tw-px-8 tw-py-7 tw-bg-white tw-rounded-lg tw-mt-4 tw-flex tw-flex-col tw-items-start tw-gap-7 tw-shadow-sm tw-w-full">
        <div class="tw-text-[#1f1f1f] tw-text-2xl tw-font-semibold font-['Poppins'] tw-leading-10 tw-mb-4">
          Al Personality Matching
        </div>

        <ul class="tw-flex tw-flex-col tw-gap-6 tw-w-full">
          <li v-for="item in 5" :key="item" class="tw-w-full">
            <div class="tw-w-full tw-space-y-3">
              <!-- Title & Percentage -->
              <div class="tw-flex tw-items-center tw-justify-between">
                <div class="tw-font-medium tw-text-black tw-text-lg tw-flex tw-flex-col md:tw-flex-row tw-items-center">
                  Extroversion <span class="tw-text-black/50 tw-text-base tw-font-normal md:tw-ms-2">653 Talents</span>
                </div>
                <div class="tw-font-medium tw-text-black tw-text-lg">69 %</div>
              </div>

              <!-- Progress Bar -->
              <div class="tw-relative tw-w-full tw-h-3 tw-bg-gray-200 tw-rounded-full tw-mt-3">
                <div class="tw-absolute tw-h-3 tw-bg-[#422E87] tw-rounded-full" style="width: 69%;"></div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="tw-px-8 tw-py-7 tw-bg-white tw-rounded-lg tw-mt-4 tw-flex tw-flex-col tw-items-start tw-gap-7 tw-shadow-sm">
      <div>
        <h1 class="tw-text-[#1f1f1f] xl:tw-text-2xl tw-font-semibold tw-leading-10 tw-text-xl">
          AI overall Feedback
        </h1>
        <p class="tw-text-[#9f9d9d] tw-text-base tw-font-normal tw-leading-normal">
          The candidate's performance in the interview was overall satisfactory.
          They demonstrated a good understanding of product management concepts
          and showcased relevant experience. However, there were some areas
          where their answers lacked depth or clarity. Overall, the candidate
          has potential but could benefit from further refining their
          communication skills and providing more concrete examples.
        </p>
      </div>

      <div class="tw-flex tw-flex-col tw-items-start">
        <div class="tw-text-lg tw-text-[#1f1f1f] xl:tw-text-xl tw-font-semibold tw-leading-loose">
          Pros:
        </div>
        <ul
          class="tw-text-[#9f9d9d] tw-text-sm tw-font-normal tw-leading-normal tw-list-disc tw-pl-5 marker:tw-text-[#DCDCFE]">
          <li>Good understanding of product management concepts</li>
          <li>Relevant experience</li>
        </ul>
      </div>
      <div class="tw-flex tw-flex-col tw-items-start">
        <div class="tw-text-[#1f1f1f] tw-text-lg xl:tw-text-xl tw-font-semibold tw-leading-loose">
          Cons:
        </div>
        <ul
          class="tw-text-[#9f9d9d] tw-text-sm tw-font-normal tw-leading-normal tw-list-disc tw-pl-5 marker:tw-text-[#DCDCFE]">
          <li>Lack of depth in some answers</li>
          <li>Lack of clarity in some answers</li>
        </ul>
      </div>
    </div>

    <!-- pieChart copy2 -->
    <div
      class="tw-flex tw-flex-col tw-gap-7 tw-shadow-sm tw-w-full tw-h-full tw-bg-white tw-px-8 tw-py-7 tw-mt-4 tw-rounded-xl">
      <div class="tw-text-[#1f1f1f] tw-text-xl tw-leading-9 xl:tw-text-2xl tw-font-semibold xl:tw-leading-10">
        Feelings during the interview
      </div>
      <div class="tw-flex xl:tw-flex-row tw-flex-col tw-gap-3">
        <div
          class="tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-between tw-px-6 tw-py-4 tw-border-2 tw-rounded-lg tw-shadow-sm">
          <div class="tw-flex tw-gap-2">
            <div class="tw-text-[#363a3a] tw-text-base tw-font-normal tw-leading-tight">
              😌
            </div>
            <div class="tw-text-[#363a3a] tw-text-base tw-font-semibold tw-leading-normal">
              Calm
            </div>
          </div>

          <div class="tw-text-[#363a3a] tw-text-base tw-font-normal tw-leading-none">
            7%
          </div>
        </div>

        <div
          class="tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-between tw-px-6 tw-py-4 tw-border-2 tw-rounded-lg tw-shadow-sm">
          <div class="tw-flex tw-gap-2">
            <div class="tw-text-[#363a3a] tw-text-base tw-font-normal tw-leading-tight">
              😊
            </div>
            <div class="tw-text-[#363a3a] tw-text-base tw-font-semibold tw-leading-normal">
              Happy
            </div>
          </div>
          <div class="tw-text-[#363a3a] tw-text-base tw-font-normal tw-leading-none">
            7%
          </div>
        </div>

        <div
          class="tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-between tw-px-6 tw-py-4 tw-border-2 tw-rounded-lg tw-shadow-sm">
          <div class="tw-flex tw-gap-2">
            <div class="tw-text-[#363a3a] tw-text-base tw-font-normal tw-leading-tight">
              😔
            </div>
            <div class="tw-text-[#363a3a] tw-text-base tw-font-semibold tw-leading-normal">
              Confused
            </div>
          </div>
          <div class="tw-text-[#363a3a] tw-text-base tw-font-normal tw-leading-none">
            7%
          </div>
        </div>

        <div
          class="tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-between tw-px-6 tw-py-4 tw-border-2 tw-rounded-lg tw-shadow-sm">
          <div class="tw-flex tw-gap-2">
            <div class="tw-text-[#363a3a] tw-text-base tw-font-normal tw-leading-tight">
              😕
            </div>
            <div class="tw-text-[#363a3a] tw-text-base tw-font-semibold tw-leading-normal">
              Sad
            </div>
          </div>

          <div class="tw-text-[#363a3a] tw-text-base tw-font-normal tw-leading-none">
            7%
          </div>
        </div>

        <div
          class="tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-between tw-px-6 tw-py-4 tw-border-2 tw-rounded-lg tw-shadow-sm">
          <div class="tw-flex tw-gap-2">
            <div class="tw-text-[#363a3a] tw-text-base tw-font-normal tw-leading-tight">
              😨
            </div>
            <div class="tw-text-[#363a3a] tw-text-base tw-font-semibold tw-leading-normal">
              Fear
            </div>
          </div>

          <div class="tw-text-[#363a3a] tw-text-base tw-font-normal tw-leading-none">
            7%
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>