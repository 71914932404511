<template>
    <div
        class="tw-px-8 tw-py-7 tw-bg-white tw-rounded-2xl tw-mt-4 tw-flex tw-flex-col md:tw-flex-row tw-items-start tw-gap-4 tw-shadow-sm tw-w-full">
        <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full md:tw-w-1/3">
            <h2
                class="tw-text-[#363a3a] tw-text-base tw-font-semibold  tw-leading-normal md:tw-border-b-0 tw-border-b-2 ">
                Interview Questions
            </h2>
            <ul
                class="tw-bg-[#e7e7e7] tw-rounded-2xl tw-flex tw-flex-col tw-border tw-border-solid tw-border-[#E7E7E7] tw-w-full tw-px-2.5 tw-py-4  tw-gap-1">
                <li>
                    <div
                        class="tw-cursor-pointer tw-bg-[#422E87] tw-rounded-full tw-py-4 tw-px-4 tw-text-white tw-text-xs tw-font-semibold tw-leading-snug tw-truncate ">
                        <span class="tw-me-2">1</span> Describe a major change Lorem ipsum dolor sit amet that
                    </div>
                </li>
                <li>
                    <div
                        class="tw-cursor-pointer tw-text-[#1b1b1b] tw-py-3 tw-px-4  tw-text-xs tw-font-semibold tw-leading-snug tw-truncate">
                        <span class="tw-me-2">2</span> What are the main duties...
                    </div>
                </li>
                <li>
                    <div
                        class="tw-cursor-pointer tw-text-[#1b1b1b] tw-py-3 tw-px-4  tw-text-xs tw-font-semibold tw-leading-snug tw-truncate">
                        <span class="tw-me-2">3</span> Describe a major change...
                    </div>
                </li>
                <li>
                    <div
                        class="tw-cursor-pointer tw-text-[#1b1b1b] tw-py-3 tw-px-4  tw-text-xs tw-font-semibold tw-leading-snug tw-truncate">
                        <span class="tw-me-2">4</span> Describe a major change...
                    </div>
                </li>
            </ul>
        </div>
        <div class="tw-flex tw-flex-col tw-w-full md:tw-w-2/3">
            <div class="tw-w-full tw-justify-center tw-items-center tw-mx-auto">
                <video-player class="vjs-custom-skin" :options="playerOptions" />
            </div>

            <div class="tw-w-full tw-flex tw-flex-col tw-border-b tw-border-solid tw-border-[#CACACA] tw-pb-1 tw-mt-6">
                <h2 class="tw-text-[#1b1b1b] tw-text-xs tw-font-normal  tw-leading-snug">
                    Question 1
                </h2>
                <div class="tw-flex tw-gap-4 tw-flex-col md:tw-flex-row tw-w-full">
                    <h1 class="tw-text-[#1b1b1b] tw-text-2xl tw-font-bold  tw-leading-9 tw-w-full ">
                        Describe a major change that occurred in a job that you held. How
                        did you adapt to this change? *
                    </h1>

                    <div
                        class="tw-bg-[#3BD4AE1A]  tw-h-14 tw-px-6 tw-py-3.5 tw-gap-2 tw-flex tw-flex-row tw-items-center tw-justify-center tw-rounded-xl">
                        <span
                            class="tw-text-center tw-text-[#3bd4ae] tw-text-base tw-font-bold tw-leading-normal tw-whitespace-nowrap">89%
                            Match</span>

                        <img src="@/assets/images/video-interviews/svg/info.svg" />
                    </div>
                </div>
            </div>

            <div class="tw-flex tw-flex-col tw-gap-2 tw-mt-4">
                <h2 class="tw-text-lg tw-text-[#292929] md:tw-text-xl tw-font-semibold  tw-leading-9 tw-bg-[#F1EDFF] tw-w-max"
                    @contextmenu.prevent>
                    Video Transcript
                </h2>

                <div class="tw-flex tw-flex-col">
                    <p class="tw-text-[#9f9d9d] md:tw-text-base tw-font-normal tw-leading-normal tw-text-sm ">
                        {{ truncatedText }}
                        <span v-if="!isExpanded && showButton">...</span>
                        <button v-if="showButton" @click.prevent="toggleView"
                            class="tw-text-black md:tw-text-base tw-font-medium tw-leading-normal tw-text-sm tw-ms-2">
                            {{ isExpanded ? 'View Less' : 'View More' }}
                        </button>
                    </p>
                </div>

                <div class="tw-flex tw-flex-col tw-gap-2 tw-mt-4">
                    <div class="tw-w-full">
                        <h2 class="tw-text-lg tw-text-[#292929] md:tw-text-xl tw-font-semibold  tw-leading-9 tw-bg-[#F1EDFF] tw-w-max"
                            @contextmenu.prevent>
                            Answer Criteria
                        </h2>
                        <ul class="tw-space-y-4 tw-mt-4">
                            <li class="tw-flex tw-items-center">
                                <div class="tw-relative tw-w-14 tw-h-14">
                                    <svg class="tw-w-full tw-h-full tw--rotate-90" viewBox="0 0 36 36"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <!-- Background Circle -->
                                        <circle cx="18" cy="18" r="15" fill="none"
                                            class="tw-stroke-current tw-text-[#FFC62933]" stroke-width="4"></circle>
                                        <!-- Progress Circle -->
                                        <circle cx="18" cy="18" r="15" fill="none"
                                            class="tw-stroke-current tw-text-[#FFC629] " stroke-width="4"
                                            stroke-dasharray="100" stroke-dashoffset="65" stroke-linecap="round">
                                        </circle>
                                    </svg>

                                    <!-- Percentage Text -->
                                    <div
                                        class="tw-absolute tw-top-1/2 tw-start-1/2 tw-transform tw--translate-y-1/2 tw--translate-x-1/2">
                                        <span class="tw-text-center tw-text-xs tw-font-bold tw-text-primary">35%</span>
                                    </div>
                                </div>
                                <p class="tw-text-gray-700 tw-ms-4">Flexibility shown in adjusting to new circumstances.
                                </p>
                            </li>

                            <li class="tw-flex tw-items-center">
                                <div class="tw-relative tw-w-14 tw-h-14">
                                    <svg class="tw-w-full tw-h-full tw--rotate-90" viewBox="0 0 36 36"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <!-- Background Circle -->
                                        <circle cx="18" cy="18" r="15" fill="none"
                                            class="tw-stroke-current tw-text-[#FFC62933]" stroke-width="4"></circle>
                                        <!-- Progress Circle -->
                                        <circle cx="18" cy="18" r="15" fill="none"
                                            class="tw-stroke-current tw-text-[#FFC629] " stroke-width="4"
                                            stroke-dasharray="100" stroke-dashoffset="65" stroke-linecap="round">
                                        </circle>
                                    </svg>

                                    <!-- Percentage Text -->
                                    <div
                                        class="tw-absolute tw-top-1/2 tw-start-1/2 tw-transform tw--translate-y-1/2 tw--translate-x-1/2">
                                        <span class="tw-text-center tw-text-xs tw-font-bold tw-text-primary">35%</span>
                                    </div>
                                </div>
                                <p class="tw-text-gray-700 tw-ms-4">Flexibility shown in adjusting to new circumstances.
                                </p>
                            </li>

                        </ul>
                    </div>
                </div>

                <div class="tw-flex tw-flex-col tw-gap-2 tw-mt-4">
                    <h2 class="tw-text-lg tw-text-[#292929] md:tw-text-xl tw-font-semibold  tw-leading-9 tw-bg-[#F1EDFF] tw-w-max"
                        @contextmenu.prevent>
                        AI feedback on the answer
                    </h2>

                    <div class="tw-flex tw-flex-col">
                        <p class="tw-text-[#9f9d9d] md:tw-text-base tw-font-normal tw-leading-normal tw-text-sm ">
                            The candidate's performance in the interview was overall satisfactory. They demonstrated a good understanding of product management concepts and showcased relevant experience. However, there were some
                        </p>
                    </div>

                </div>

                      <!-- Pros -->

            <div class="tw-flex tw-flex-col">
                <h2 class="tw-text-[#292929] tw-text-xl tw-font-semibold  tw-leading-9 bg-[#f1ecff] w-fit">
                    Pros:
                </h2>
                <ul
                    class="tw-text-[#9f9d9d] tw-text-sm tw-font-normal  tw-leading-normal tw-list-disc tw-pl-5 marker:text-[#DCDCFE]">
                    <li>
                        The candidate demonstrates the ability to adapt to major changes
                        in a job
                    </li>
                    <li>
                        They show proactive behavior by asking for reasons behind the
                        change and recreating the plan
                    </li>
                    <li>
                        The candidate emphasizes the importance of ensuring everyone is on
                        the right direction
                    </li>
                </ul>
            </div>

            <!-- Cons -->

            <div class="tw-flex tw-flex-col">
                <h2 class="tw-text-[#292929] tw-text-xl tw-font-semibold  tw-leading-9 bg-[#f1ecff] w-fit">
                    Cons:
                </h2>
                <ul
                    class="tw-text-[#9f9d9d] tw-text-sm tw-font-normal  tw-leading-normal tw-list-disc tw-pl-5 marker:text-[#DCDCFE]">
                    <li>None observed</li>
                </ul>
            </div>


            </div>



        </div>
    </div>
</template>

<script>
import 'video.js/dist/video-js.css';
import { videoPlayer } from 'vue-video-player';

export default {
    components: { videoPlayer },
    data() {
        return {
            playerOptions: {
                autoplay: false,
                controls: true,
                sources: [
                    {
                        src: 'https://www.w3schools.com/html/mov_bbb.mp4',
                        type: 'video/mp4'
                    }
                ],
                fluid: true
            },
            isExpanded: false,
            fullText: "The candidate's performance in the interview was overall satisfactory. They demonstrated a good understanding of product management concepts and showcased relevant experience. However, there in the interview was overall satisfactory. They demonstrated a good understanding of product management concepts and showcased relevant experience. However, there"
        };
    },
    computed: {
        truncatedText() {
            return this.isExpanded ? this.fullText : this.fullText.slice(0, 170);
        },
        showButton() {
            return this.fullText.length > 100;
        }
    },
    methods: {
        toggleView() {
            this.isExpanded = !this.isExpanded;
        }
    }
};
</script>
<style lang="scss" scoped></style>