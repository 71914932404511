<template>
  <div>
    <div class="shareable-link__assessments" v-if="!isResponse">
      <div
        class="d-flex w-100 align-items-center justify-content-start"
        v-if="!isResponse"
      >
        <skeleton-loader-vue
          v-if="!isResponse"
          type="rect"
          :width="800"
          :height="30"
          animation="fade"
          rounded
        />
      </div>
    </div>

    <div
      class="shareable-link__assessments"
      v-if="isResponse && assessments && assessments.length"
    >
      <div class="d-flex justify-content-between">
        <div class="shareable-link__assessments__header">
          <div class="d-flex">
            <img loading="lazy" class="me-2 tw-h-6 tw-w-6 " src="@/assets/images/ic_verifed.svg" /> {{$t('sharebleLink.passedOurAssessment')}}
            {{ assessments.length == 1 ? $t('sharebleLink.assessment') : $t('sharebleLink.assessments') }}
          </div>
        </div>
        <div class="d-flex align-items-start">
          <span
            class="shareable-link__assessments__count"
            v-if="assessments && false"
            >{{ assessments.length }}</span
          >
          <img loading="lazy"
            @click="showAssessments = !showAssessments"
            class="shareable-link__assessments__open"
            src="@/assets/images/icons/ic_arrow-dropdown_8.svg"
            :class="
              showAssessments
                ? 'link__assessments__open-show'
                : 'link__assessments__open-hide'
            "
          />
        </div>
      </div>
      <transition name="fade">
        <div
          v-show="showAssessments && assessments.length"
          style="margin-top: 2rem"
        >
          <div v-for="(assessment, i) in assessments" :key="i">
            <div class="d-flex flex-column w-100">
              <div class="d-flex">
                <a
                  :href="seletAssessment(assessment.assessments).report"
                  target="_blank"
                  class="text-purple weight-bold"
                  v-if="seletAssessment(assessment.assessments).view_status"
                  ><span v-tooltip="handleAssessmentName(assessment)"
                    >{{ handleAssessmentName(assessment) }}
                  </span></a
                >
                <p
                  class="text-black"
                  v-if="!seletAssessment(assessment.assessments).view_status"
                >
                  <span v-tooltip="handleAssessmentName(assessment)"
                    >{{ handleAssessmentName(assessment) }}
                  </span>
                </p>
                <div
                  class="ms-auto"
                  v-if="seletAssessment(assessment.assessments).view_status"
                >
                  <span class="score">
                    {{ seletAssessment(assessment.assessments).percentage }}
                  </span>
                  <span class="precentage"> /100 </span>
                </div>
              </div>
              <hr v-if="i != assessments.length - 1" />
            </div>
            <div class="tw-flex tw-flex-col tw-w-full tw-mt-6">
              <div class="tw-flex">
                 <img class="tw-me-2" height="22px" width="22px" src="@/assets/images/client/ic_video.svg" /> 
                <span class="tw-text-[#1e2022] tw-text-lg tw-font-semibold  tw-leading-7"> {{$t('sharebleLink.watchVideo')}}</span>
              </div>
              <hr />
                <div class="d-flex">
                <a
                  :href="'/sq/client/video-interviews/'+ $route.params.id"
                  target="_blank"
                  class="text-purple weight-bold"
                  >
                  <span>Psychometric results</span>
                  </a>
               
                <div
                  class="ms-auto"
                >
                  <span class="score">
                    {{vdeoInterview.result[0].overall_score}}  
                  </span>
                  <span class="precentage"> /100 </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { truncate } from "@/utils/utils";
export default {
  props: ["isResponse", "assessments", "vdeoInterview"],
  data() {
    return {
      showAssessments: false,
      staticToolTip: "PHP skill Report",
      loading: false,
    };
  },
  computed: {},
  methods: {
    truncate,
    seletAssessment(assessments) {
      return [...assessments].sort((a, b) =>
        a.percentage < b.percentage ? 1 : -1
      )[0];
    },
    handleAssessmentName(assessmentObject) {
      let { assessments, name } = assessmentObject;
      let assessmentName = "";
      let selectTechStack = assessments.find(
        (assessment) => assessment.type == "mcq"
      );
      if (assessments[0]) {
        if (
          assessmentObject.type == 1 &&
          (assessmentObject.id == 1 ||
            assessmentObject.id == 2 ||
            assessmentObject.id == 3)
        ) {
          assessmentName = `${name} ( ${selectTechStack.tech_stack?.join(
            ","
          )} )  `; // "V${assessments[0].version}"
          return assessmentName;
        } else {
          assessmentName = `${name} `; //"V${assessments[0].version}"
          return assessmentName;
        }
      }
    },
  },
  watch: {
    assessments: {
      handler() {},
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.shareable-link__assessments {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 3%);
  position: relative;
  padding: 20px;
  margin: 0px;
  margin-top: 30px;

  .assessment-row {
    align-items: baseline;
  }

  hr {
    margin: 1.5rem 0;
    color: #edebeb;
    border: 0;
    border-top: 1px solid;
    opacity: 1;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .weight-bold {
    font-weight: 600;
  }

  .score {
    font-weight: bold;
    font-size: 22px;
  }

  .precentage {
    font-size: 11px;
    font-weight: 500;
  }

  .text-purple {
    color: #422e87;
  }

  .shareable-link__assessments__open {
    height: 10px;
    margin-left: 1rem;
    cursor: pointer;
    margin-top: 10px;
  }

  .link__assessments__open-show {
    transform: rotate(180deg);
  }

  .link__assessments__open-hide {
    transform: rotate(0deg);
  }

  &__header {
    color: #292929;
    font-weight: 600;
    font-size: 21px;
    display: flex;
    align-items: center;
  }

  &__count {
    display: flex;
    flex-wrap: wrap;
    background-color: #f8f8f8;
    border-radius: 18px;
    color: #292929;
    padding: 8px 15px;
    margin-right: 10px;
    font-size: 15px;
    margin-right: 5px;
    font-weight: 500;
  }

  @media (max-width: 576px) {
    margin-top: 15px;
    padding: 15px;
    margin: 0;
    margin-top: 15px;

    .weight-bold {
      font-weight: 600;
      font-size: 14px;
    }

    .shareable-link__assessments__open {
      height: 10px;
      width: 17px;
    }

    &__header {
      font-size: 17px;

      img {
        height: 23px;
        width: 23px;
      }
    }
  }
}
</style>